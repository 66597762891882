<template>
  <div class="branch-top-panel">
    <div class="is-flex is-justify-content-flex-end">
      <div class="add-branch">
        <el-button type="primary" icon="el-icon-plus" @click="openModal">
          Добавить категорию
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryTopPanel',
  methods: {
    openModal() {
      this.$emit('create-category');
    },
  },
};
</script>
