<template>
  <div class="app-category">
    <category-top-panel @create-category="openModal" />
    <category-table />
    <category-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import CategoryTable from './category-table/category-table.vue';
import CategoryModal from './category-modal/category-modal.vue';
import { FETCH_CATEGORIES } from './shared/state/types/action_types';
import CategoryTopPanel from './category-top-panel/category-top-panel.vue';

export default {
  name: 'Category',
  components: { CategoryTable, CategoryModal, CategoryTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить категорию',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_CATEGORIES, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>
