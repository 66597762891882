<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="category" :model="category" :rules="formRules" @submit.prevent.native="saveFn">
      <el-form-item label="Название" prop="title">
        <el-input v-model="category.title" placeholder="Название категории" autocomplete="off" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING, SELECTED, ERROR } from '../shared/state/types/getter_types';
import {
  STORE_CATEGORY,
  UPDATE_CATEGORY,
  SELECT_CATEGORY_RESET,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'CategoryModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить филиал',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      formRules: {
        title: [{ required: true, message: 'Введите название', trigger: 'blur' }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({ loading: LOADING, category: SELECTED, error: ERROR }),
  },
  methods: {
    ...mapActions({
      store: STORE_CATEGORY,
      update: UPDATE_CATEGORY,
      reset: SELECT_CATEGORY_RESET,
    }),
    closeModal() {
      this.visible = false;
      this.reset();
    },
    async saveFn() {
      switch (this.action) {
        case 'edit':
          await this.update(this.category);
          if (this.error) {
            this.showErrorMessage('Ошибка обновления');
          }
          this.showSuccessMessage('Успешно обновлен');
          this.closeModal();
          break;
        default:
          await this.store(this.category);
          if (this.error) {
            this.showErrorMessage('Ошибка добавления');
          }
          this.showSuccessMessage('Успешно добавлен');
          this.closeModal();
          break;
      }
    },
  },
};
</script>
